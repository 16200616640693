<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchDatas"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: index -->
        <template #cell(#)="data">
          <span class="text-center">
              <feather-icon v-if="data.detailsShowing" icon="MinusCircleIcon" size="20" @click="data.toggleDetails" class="text-danger cursor-pointer " />
              <feather-icon v-else icon="PlusCircleIcon" size="20" @click="data.toggleDetails" class="text-primary cursor-pointer" />
            
          </span>
        </template>


        <!-- full detail on click -->
        <template #row-details="row">
          <b-card>
            <b-row v-if="row.item.organization_name" class="mb-0 d-sm-none d-xs-block">
              <b-col
                md="4"
              >
                <strong>Organization : </strong>{{ row.item.organization_name }}
              </b-col>
            </b-row>
            <b-row class="mb-0 d-sm-none d-xs-block">
              <b-col
                md="4"
              >
                <strong>Date Time : </strong>{{ dateTime(row.item.created_at) }}
              </b-col>
            </b-row>
            <b-row class="mb-0">
              <b-col
                md="4"
              >
                <strong>IP : </strong>{{ row.item.IP }}
              </b-col>
            </b-row>
          </b-card>
        </template>

        <!-- Column: Name -->
        <template #cell(Log)="data">
          <div class="wrap">
              {{ data.item.module_name }} -  "{{ data.item.reference }}" <span :class="`text-${resolveStatusColor(data.item.action_value)}`">{{ data.item.action_value }}</span>
          </div>
        </template>

        <!-- Column: action_value -->
        <template #cell(actioned_by)="data">
          <div class="">
              {{ data.item.actioned_by }}
          </div>
        </template>

        <!-- Column: Name -->
        <template #cell(created_at)="data">
            <span class="text-center" :title="data.item.id">{{ dateTime(data.item.created_at) }}</span>
        </template>


      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalData"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ListFilters from './ListFilters.vue'
import useList from './useList'
import logsStoreModule from '../logsStoreModule'
import { dateTime } from '@core/utils/filter'

export default {
  components: {
    ListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,

    vSelect,
  },
  directives: {
    Ripple,
  },

  setup() {
    const LOG_APP_STORE_MODULE_NAME = 'app-log'

    // Register module
    if (!store.hasModule(LOG_APP_STORE_MODULE_NAME)) store.registerModule(LOG_APP_STORE_MODULE_NAME, logsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LOG_APP_STORE_MODULE_NAME)) store.unregisterModule(LOG_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { label: 'Active', value: "0" },
      { label: 'Archived', value: "1" },
    ]

    const {
      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
      resolveStatusColor,

      // Extra Filters
      statusFilter,
    } = useList()

    return {

      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      dateTime,

      // UI
      resolveStatusColor,

      statusOptions,

      // Extra Filters
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
