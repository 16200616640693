import axios from '@axios'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')

import {checkResponseStatus} from '../checkResponseStatus'


export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDatas(ctx, queryParams) {
      return new Promise((resolve, reject) => {
       axios
          .get('/api/v1/logs', { params: queryParams })
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
              
          }).catch(error => reject(error))
      })
    },

  },
}
