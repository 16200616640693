import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useList() {
  // Use userData
  const userData = JSON.parse(localStorage.getItem('userData'))

  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  var tableColumns = []
  if(userData.user_type.isSuper){
    tableColumns = [
      { key: '#', sortable: false },
      { key: 'Log', sortable: false },
      { key: 'actioned_by', label: 'Action By', sortable: true },
      { key: 'organization_name', label: 'Organization', sortable: true },
      { key: 'created_at', label: 'Date Time', sortable: false }
    ]
  }else{
    tableColumns = [
      { key: '#', sortable: false },
      { key: 'Log', sortable: false },
      { key: 'actioned_by', label: 'Action By', sortable: true },
      { key: 'created_at', label: 'Date Time', sortable: false }
    ]

  }
  const perPage = ref(10)
  const totalData = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalData.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchDatas = (ctx, callback) => {
    store
      .dispatch('app-log/fetchDatas', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then(response => {
        const datas = response.data.data
        const total = datas.length

        callback(datas)
        totalData.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching logs list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*


  const resolveStatusColor = action_value => {
    if (action_value === 'Created') return 'success'
    else if (action_value === 'Updated') return 'warning'
    else if (action_value === 'Deleted') return 'danger'
    return 'primary'
  }


  return {
    fetchDatas,
    tableColumns,
    perPage,
    currentPage,
    totalData,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    refetchData,
    resolveStatusColor,
  }
}
